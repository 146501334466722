package app

import react.*
import react.dom.*
import logo.*
import ticker.*

class App : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("App-header") {
            h2 {
                +"Manzurur Rahman Khan"
            }
        }
        p("App-intro") {
            ul {
                li {
                    a("https://github.com/sidky") { +"Github"}
                }
                li {
                    a("https://www.linkedin.com/in/manzururkhan/") { +"Linkedin"}
                }
                li {
                    a("https://500px.com/sidky") { +"500px" }
                }
                li {
                    a("https://www.flickr.com/photos/sidky/") { +"Flickr" }
                }
                li {
                    a("https://twitter.com/ManzururKhan") { +"Twitter" }
                }
            }
        }
    }
}

fun RBuilder.app() = child(App::class) {}
